import React from 'react';
// import Seo from '../components/Seo';
import { contactHolder } from './contact.module.scss';
import BuildButton from '../components/buildButton';

export default function StaffPage() {
  return (
    <article className={contactHolder}>
      <h1>MCX Staff Page</h1>
      <p>
        <BuildButton />
      </p>
    </article>
  );
}
