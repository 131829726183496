import React from 'react';
import { useState } from 'react';

export default function BuildButton() {
  const [status, setStatus] = useState('ready');

  const buildSite = () => {
    setStatus('building');
    fetch('https://api.netlify.com/build_hooks/63beefebaa0e89009e0b9779', {
      method: 'POST',
    })
      .then((response) => {
        if (response.ok) {
          setStatus('building');
        }
      })
      .catch((error) => {
        setStatus('error');
      });
  };

  return (
    <>
      {status === 'ready' && <button onClick={() => buildSite()}>Rebuild the site</button>}

      {status === 'building' && <span>Site is being rebuilt. This normally takes about 10 - 15 mins...</span>}

      {status === 'error' && <span>Sorry, there's been an error. Contact Ben for help.</span>}
    </>
  );
}
